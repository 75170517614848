import React, { useEffect } from 'react';

const RESUME_URL = 'https://pavol-resume.s3.eu-central-1.amazonaws.com/karas_pavol_resume.pdf';

function Resume() {
  useEffect(() => {
    window.open(
      RESUME_URL
    );
  }, []);
  return (
    <div>
      <span role="img" aria-label="resume">
        📃
      </span>{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={RESUME_URL}
      >
        Resume
      </a>{' '}
    </div>
  );
}

export default Resume;
