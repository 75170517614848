import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Responses from './Responses';

function Terminal() {
  const [input, setInput] = useState('');
  const [inputsArr, setInputsArr] = useState([]);
  const textAreaRef = useRef(null);

  useEffect(() => {
    textAreaRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [inputsArr]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  })

  const updateInput = event => {
    setInput(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setInputsArr([...inputsArr, input]);
      setInput('');
      event.preventDefault();
    }
    // ctrl+l clears console
    else if (event.keyCode === 76 && event.ctrlKey) {
      clearConsole();
      event.preventDefault();
    }
  };

  const clearConsole = () => {
    setInputsArr([]);
  };

  return (
    <TerminalContainer>
      {inputsArr.length > 0
        ? inputsArr.map((inp, idx) => {
          return (
            <UserResponse key={idx}>
              <div>$ {inp}</div>
              {inp === 'clear' || inp === 'cls' ? clearConsole() : <Responses input={inp} />}
            </UserResponse>
          );
        })
        : null}
      <TextWrapper ref={textAreaRef}>
        <StyledTextArea
          rows="1"
          autoFocus={true}
          onChange={updateInput}
          value={input}
          onKeyPress={handleKeyPress}
        />
      </TextWrapper>
    </TerminalContainer>
  );
}

export default Terminal;

const TerminalContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
`;

const UserResponse = styled.div`
  margin-left: 0.4rem;
  padding: 0.6rem 0;
  border-bottom: 1px solid black;
`;

const TextWrapper = styled.div`
  position: relative;
  &:before {
    content: '$ ';
    position: absolute;
    top: 0.5rem;
    left: 0.4rem;
    width: auto;
    height: 12px;
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  background: none;
  font-family: 'Courier New', Courier, monospace;
  border: 0;
  padding: 0.6rem;
  padding-left: 1.6rem;
  overflow: auto;
  display: block;
  width: 100%;
  outline: none;
  font-size: inherit;
`;
