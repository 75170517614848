import React from 'react';
import styled from 'styled-components';

import Details from './components/Details';
import Terminal from './components/Terminal';

function App() {
  return (
    <AppContainer>
      <Details />
      <Terminal />
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  margin: 0 1rem 1rem;
`;
