import React from 'react';
import styled from 'styled-components';

function Contacts() {
  return (
    <div>
      <StyledTable>
        <tr>
          <td style={colors.github}>GitHub - personal</td>
          <td>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/Pav0l">/Pav0l</a>
          </td>
        </tr>

        <tr>
          <td style={colors.github}>GitHub - work</td>
          <td>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/karaspa">/karaspa</a>
          </td>
        </tr>

        <tr>
          <td style={colors.linkedin}>LinkedIn</td>
          <td>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/pavolkaras/">/in/pavolkaras</a>
          </td>
        </tr>

        <tr>
          <td style={colors.gmail}>Mail</td>
          <td>
            <a target="_blank" rel="noopener noreferrer" href="mailto: pavol.karas@gmail.com">Get in touch</a>
          </td>
        </tr>
      </StyledTable>
    </div>
  );
}

export default Contacts;

const StyledTable = styled.table`
  tr {
    td:first-child {
      font-weight: 600;
    }
    td:nth-child(2) {
      padding: 0 1rem;
    }
  }
`;

const colors = {
  linkedin: { color: '#0073b1' },
  github: { color: '#111111' },
  gmail: { color: '#d93025' }
};
