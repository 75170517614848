import React from 'react';
import Help from './responses/Help';
import About from './responses/About';
import Work from './responses/Work';
import Contacts from './responses/Contacts';
// import Skills from './responses/Skills';
import Resume from './responses/Resume';
import NotFound from './responses/NotFound';

function Responses({ input }) {
  switch (input.toLowerCase()) {
    case 'help':
      return <Help />;
    case 'about':
      return <About />;
    case 'work':
      return <Work />;
    case 'contacts':
      return <Contacts />;
    // case 'skills':
    //   return <Skills />;
    case 'resume':
      return <Resume />;
    case 'rm -rf':
      return (
        <div>
          Whooops! You just deleted the universe. Good Bye!{' '}
          <span role="img" aria-label="alien">
            👽
          </span>
        </div>
      );
    case 'hi':
      return (
        <div>
          <span role="img" aria-label="wave">
            👋
          </span>
        </div>
      );
    case 'postman':
      return (
        <div>
          Use Postman first!{' '}
          <span role="img" aria-label="rocket">
            🚀
          </span>
        </div>
      );
    case 'dir':
    case 'ls':
      return <div>node_modules package.json yarn.lock top_secret_folder</div>;
    case 'cd top_secret_folder':
      return (
        <div>
          {' '}
          <span role="img" aria-label="stop">
            ⛔
          </span>{' '}
          Wow, wow, wow... stop right there{' '}
          <span role="img" aria-label="stop">
            ⛔
          </span>
        </div>
      );

    default:
      return <NotFound input={input} />;
  }
}

export default Responses;
