import React from 'react';

function Work() {
  return (
    <div>

      {/* Tieme Ndo CRM */}
      <div>
        <div>
          <b>Tieme Ndo CRM</b>
        </div>
        <ul>
          <li>
            - Leading a team of 8 developers building an internal CRM for a non-profit in Ghana
            providing micro-loans or seeds to local farmers and communities.
          </li>
          <li>
            - Overviewed the development process, organized weekly sprint reviews with stakeholders,
            developed multiple backend and frontend features, troubleshooted issues with the team, ...
          </li>
          <li>
            - Worked with Node.js, Express.js, MongoDB, Winston, Mocha+Chai, React, Redux, JWT
          </li>
          <li>
            -{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/tieme-ndo"
            >
              GitHub
            </a>
          </li>
        </ul>
      </div>
      <br />
      {/* FrontDesk */}
      <div>
        <div>
          <b>FrontDesk</b>
        </div>
        <ul>
          <li>
            - FrontDesk is a chat app that provides hotels with a workflow for
            managing guest requests and guests with a frictionless service
          </li>
          <li>
            - Developed translation functionality, QR code generation, user
            authentication, restricted routing, part of the REST API endpoints
            and multiple front end components
          </li>
          <li>
            - Worked with Express.js, MongoDB, Jest, React, Redux,
            Google Cloud API, JWT
          </li>
          <li>
            -{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/lambdalabs-eu1-webchat"
            >
              GitHub
            </a>
          </li>
          <li>
            -{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/watch?v=VMWvTaHI6_E"
            >
              Youtube demo
            </a>
          </li>
        </ul>
      </div>
      <br />

      {/* rate-a-website */}
      <div>
        <div>
          <b>Happiest websites</b> -{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://happiest-websites.netlify.com/"
          >
            Web client
          </a>{' '}
          &{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://chrome.google.com/webstore/detail/rate-a-website/oplipkhodadjellpakfommojjmcikidf"
          >
            Chrome Extension
          </a>{' '}
          &{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addons.mozilla.org/en-US/firefox/addon/rate-a-website/"
          >
            Firefox Extension
          </a>
        </div>
        <ul>
          <li>
            - "Happiest websites" project is a small side project, where I build
            full stack app together with browser extension which allows its
            users to rate websites. Ratings are processed by server and weighted
            average rating is dispayed on a web client
          </li>
          <li>
            - Build the front end, back end and extensions for Chrome and
            Firefox
          </li>
          <li>
            - Worked with Express, Postgres, Joi, Knex, SQLite,
            React, CSS
          </li>
          <li>
            - Github{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Pav0l/rate-a-website-app"
            >
              web app
            </a>,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Pav0l/rate-a-website-extension"
            >
              extension
            </a>,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Pav0l/rate-a-website-server"
            >
              backend
            </a>
          </li>

        </ul>
      </div>
      <br />

      {/* Clipit */}
      <div>
        <div>
          <b>Clipit</b> -{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://clipit.fyi"
          >
            https://clipit.fyi
          </a>
        </div>
        <ul>
          <li>
            - Clipit is a platform for Streamers to reward their supporters with collectible & tradeable Clip NFTs
          </li>
          <li>
            - Implemented smart contracts according to ERC721 standard, Market and Auction contracts. Built subgraphs to
            index data from the contracts
          </li>
          <li>
            - Developed a backend service in Golang to download the Clips from Twitch, verify their ownership, upload to IPFS
            and cryptographically sign them for Smart Contracts to verify the signatures
          </li>
          <li>
            - Developed multiple frontend clients (App, Twitch Extension, Demo page) that consume data from the subgraph,
            IPFS and backend service and provide UI for the Streamer and Viewers to support, trade and collect Clip NFTs
          </li>
          <li>
            - Worked with Nodejs, React, Go, Solidity, Google Cloud, Docker, Firebase, TheGraph, Hardhat
          </li>
          <li>
            - {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Pav0l/Clipit"
            >
              Github
            </a>
          </li>

        </ul>
      </div>
      <br />

      {/* Slido */}
      <div>
        <div>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.sli.do/"
          >
            <b>Slido @ Cisco</b>
          </a>
        </div>
        <ul>
          <li>
            - Leading a team of developers responsible for Slido meeting integrations (Webex, MS Teams, Zoom)
          </li>
          <li>
            - Worked with Typescript, Node.js, React, MySQL, Redis, Socket.io, AWS
          </li>
        </ul>
      </div>
      <br />

      <div>
        More projects{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Pav0l"
        >
          here
        </a>{' '}
      </div>
    </div>
  );
}

export default Work;
