import React from 'react';
import styled from 'styled-components';

function Details() {
  return (
    <DetailsContainer>
      <DetailsText>
        <h3>Hello World!</h3>
        <div>
          <p>
            My name is <Name>Pavol</Name> and I'm a software engineering tech lead at <a href='https://www.sli.do/' target="_blank"
              rel="noopener noreferrer">Slido</a> (now part of <a href='https://blogs.cisco.com/news/346777' target="_blank"
                rel="noopener noreferrer">Cisco</a>).
          </p>

          <p>Type <b>help</b> in the terminal below to find out more.</p>
          <p>

          </p>

        </div>
      </DetailsText>
    </DetailsContainer>
  );
}

export default Details;

const DetailsContainer = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid black;
  position: sticky;
  top: 0;
  background: white;

  @media (max-width: 1024px) {
    position: relative;
  }
`;

const DetailsText = styled.div`
  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;

    b {
      color: #d93025;
    }
  }
`;

const Name = styled.span`
  font-weight: 600;
`;
