import React from 'react';
import styled from 'styled-components';

function NotFound({ input }) {
  return <StNotFound>{input}: command not found</StNotFound>;
}

export default NotFound;

const StNotFound = styled.div`
  color: red;
`;
