import React from 'react';

function About() {
  return (
    <div>
      <div>
        <b>
          Hello there!{' '}
          <span role="img" aria-label="wave">
            👋
          </span>
        </b>
      </div>
      <div>
        My name is <b>Pavol</b> and I'm a Software Engineering Tech Lead at Cisco{' '}
        <span role="img" aria-label="coder">
          👨‍💻
        </span>
        .
      </div>
      <br />
      <div>
        I have a degree in Chemical Technologies{' '}
        <span role="img" aria-label="chemist">
          👨‍🔬
        </span>
        {' '}and I've worked in the industry for over 5 years from junior, through
        senior to leadership positions.
        <div>

        </div>
        <div>
          I've also helped launch <span role="img" aria-label="bike">
            🚴‍‍
          </span>{' '}
          bike-sharing in Bratislava - {' '}
          <a target="_blank" rel="noopener noreferrer" href="http://slovnaftbajk.sk/">check it out!</a>
        </div>
      </div>
      <div>
        But my passion to build things brought me to software development. Check out what I've worked on by typing "work" in the temrinal.
      </div>

      <br />

      <div>
        Besides software, I love
        climbing{' '}
        <span role="img" aria-label="climb">
          🧗‍
        </span>,{' '}
        reading{' '}
        <span role="img" aria-label="book">
          📚
        </span>{' '} and am always learning{' '}
        <span role="img" aria-label="student">
          👨‍🎓
        </span>.
      </div>
    </div>
  );
}

export default About;
