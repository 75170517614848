import React from 'react';

function Help() {
  return (
    <div>
      Available commands:
      <br />
      <div>
        <b>help</b>: list of available commands
      </div>
      <div>
        <b>clear</b>: clears the console
      </div>
      <div>
        <b>about</b>: info about me
      </div>
      <div>
        <b>work</b>: my experiences and projects I worked on
      </div>
      {/* <div>
        <b>skills</b>: list of tech stack I worked with
      </div> */}
      <div>
        <b>contacts</b>: contact me
      </div>
      <div>
        <b>resume</b>: my resume
      </div>
    </div>
  );
}

export default Help;
